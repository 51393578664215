import React from 'react'
import WebsiteDown from "../components/websitedown/WebsiteDown";

const DownWebSite = () => {
    return (
        <div className="mid">
            <div className="mid_background2">
                <WebsiteDown />
            </div>
        </div>
    )
}
export default DownWebSite
