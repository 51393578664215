import React from 'react'
import Error404 from "../components/404/error404";
import "./Error.scss"

const Error = () => {
    return (
        <div className="mid">
            <div className="mid_background2">
                    <Error404 />
            </div>
        </div>
    )
}
export default Error
