import React from 'react'
import "./ContactsComponent.scss"

const ContactsComponent = () => {
    return (
        <div className="ContactsComponent">
            <p>
               Наші контакти:<br/>
                <a href="mailto:forsbrandua@gmail.com">forsbrandua@gmail.com</a><br/>
            </p>
        </div>
    )
}
export default ContactsComponent
